import React from "react"
import SEO from "../components/seo";
import { graphql } from "gatsby"
import Img from "gatsby-image"

const comingSoon = ({data}) => {
  // todo update with kickstarer project page
  const campaignURL = 'https://www.kickstarter.com/projects/inventingheron/career-launchpad-kits-if-you-can-see-it-you-can-be-it'
  console.log()
  return (
    <>
      <SEO title="Coming Soon" />

      <section className="contact contact-header" style={{backgroundColor: '#f6f6f6'}}>
        <div className="inner inner-contact inner-contact-header">
          <h1>Our New Career Platform is Launching Soon!</h1>
        </div>
      </section>

      <section>
        <div className="inner inner-intro">
          <p>
            <p>
              Thank you for visiting! Our new platform will be ready by January 2021. In the meantime, please support us on Kickstarter <a href={campaignURL}>here</a>.
            </p>
            <Img
              fluid={data.file.childImageSharp.fluid}
              backgroundColor={"#00324b"}
              alt="illustration of a student imagining her career options"
            />
          </p>
        </div>
      </section>
    </>
  )
}

export const query = graphql`
  query myQuery {
    file(relativePath: {eq: "coming-soon.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1300, quality:90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default comingSoon
